<template>
  <q-expansion-item
    v-if="useCheckPermissions(item.permissions)"
    v-model="isExpanded"
    :icon="item?.icon || undefined"
    class="rounded-by-style mx-2.5 flex flex-1 flex-row gap-3 opacity-90"
    :dark="$q.dark.isActive"
    :header-class="`p-0 ${currentParentRouteToggled ? '!bg-fr-gray-300/50 dark:!bg-fr-primary-500/20' : ''}`"
    hide-expand-icon
    :content-inset-level="0.07"
  >
    <template #header>
      <div
        class="rounded-by-style flex w-full flex-row flex-nowrap justify-between p-3 text-[14px] font-light tracking-wide"
      >
        <div class="flex w-full flex-auto flex-row items-center gap-3">
          <q-icon v-if="item?.icon" :name="item.icon" />
          <span>{{ item.title }}</span>
        </div>
        <div class="flex flex-row flex-nowrap items-center gap-3">
          <q-badge
            v-if="item?.isBeta"
            color="primary"
            text-color="white"
            label="WIP"
          />

          <q-icon
            v-if="item.children && item.children !== null"
            :class="`transition-transform duration-300 ${
              isExpanded ? 'rotate-180' : ''
            }`"
            name="keyboard_arrow_down"
            size="xs"
          />
        </div>
      </div>
    </template>

    <template #default>
      <div class="bg-fr-gray-300/40 dark:bg-fr-gray-900/30">
        <template v-for="child in item.children" :key="child.title">
          <menu-item-component
            v-if="!child.children || child.children === null"
            :item="child"
            padding-left="24px"
          />
          <expansion-item-component
            v-if="child.children && item.children !== null"
            :item="child"
            padding-left="24px"
          />
        </template>
      </div>
    </template>
  </q-expansion-item>
</template>

<script setup lang="ts">
import type { RouteMeta } from 'vue-router'
import menuItemComponent from './menu-item-component.vue'

const props = defineProps<{
  item: RouteMeta
}>()
const isExpanded = ref(false)

const item = computed(() => props.item)

const currentParentRouteToggled = ref(false)

watch(
  () => useRoute().path,
  () => {
    currentParentRouteToggled.value =
      useRoute().path.startsWith(item.value.path) || false

    isExpanded.value = currentParentRouteToggled.value
  },
  { immediate: true }
)
</script>

<style lang="sass">
.q-expansion-item__container
  width: 100%

.q-expansion-item--expanded
  @apply shadow-[inset_2px_0px] shadow-fr-gray-400 dark:shadow-fr-primary-700/50 opacity-100
  //@apply shadow-[inset_3px_0px] shadow-fr-secondary-500

.chowey
  .q-expansion-item
    .q-focus-helper
      @apply rounded-lg
    .q-item
      @apply rounded-lg

.neo
  .q-expansion-item
    .q-focus-helper
      @apply rounded-sm
    .q-item
      @apply rounded-sm
</style>
